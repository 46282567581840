import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import { StaticImage } from 'gatsby-plugin-image';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';

const useStyles = makeStyles((theme) => ({
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      maxWidth: 500,
    },
  },
}));

const Hero = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid
        container
        justifyContent="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
                High speed visualisation for large triangulated
                <Typography component="span" variant="inherit" color="primary">
                  {' '}
                  mesh
                </Typography>{' '}
                and{' '}
                <Typography component="span" variant="inherit" color="primary">
                  point cloud
                </Typography>{' '}
                datasets
              </span>
            }
            subtitle="VRGS uses its own dedicated graphics engine to give the highest levels of performance, essential for working with large virtual outcrops and to give the high frame rates required for VR."
            align="left"
            disableGutter
            titleVariant="h2"
          />
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <div
            data-aos="fade-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <StaticImage
              src="images/annotations-2.jpg"
              alt="Annotate your models to provide a rich virtual field trip experience"
              placeholder="blurred"
              className={classes.image}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
