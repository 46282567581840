import React, { Fragment } from 'react';
import { colors, Divider } from '@mui/material';
import Section from 'components/organisms/Section/Section';
import SectionAlternate from 'components/organisms/SectionAlternate/SectionAlternate';
import { Advantages, Hero, Spaces, VideoSection } from './components';
import makeStyles from '@mui/styles/makeStyles';
import { advantages } from './data';
import SEO from '../../components/SEO';

export const Head = () => (
  <SEO description="Visualise you virtual outcrops in either desktop or Virtual Reality modes" />
);

const useStyles = makeStyles((theme) => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  shape: {
    background: theme.palette.alternate.main,
    borderBottomRightRadius: '50%',
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
}));

const Visualisation = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Section className={classes.pagePaddingTop}>
        <VideoSection
          videoSrc="https://www.youtube.com/embed/LU27xrMvK_Q"
          videoTitle="Desktop and Virtual Reality in VRGS."
          videoSubtitle="Virtual Reality Geological Studio has a long history of working with VR, from early days of integrating with stereo walls to the VR headsets today. Use Virtual Reality as it suits you in your worflow with easy desktop to VR transition."
        />{' '}
      </Section>
      <Section className={classes.pagePaddingTop}>
        <Hero />
      </Section>
      <SectionAlternate>
        <Advantages data={advantages} />
      </SectionAlternate>
      <Divider />
      <Section>
        <Spaces />
      </Section>
      <Divider />
    </Fragment>
  );
};

export default Visualisation;
