export const advantages = [
  {
    icon: '/images/svg/desktop-solid.svg',
    title: 'Desktop Mode',
    subtitle:
      'The main working mode of VRGS with maximum control over navigation and interpretation.',
  },
  {
    icon: '/images/svg/vr-cardboard-solid.svg',
    title: 'VR Mode',
    subtitle:
      'Switch into VR to give a different perspective of your data, and a better sense of scale.',
  },
  {
    icon: '/images/svg/arrow-right-arrow-left-solid.svg',
    title: 'Rapid Transition',
    subtitle:
      'Quickly change between desktop and VR modes at a touch of a button.',
  },
  {
    icon: '/images/svg/briefcase-solid.svg',
    title: 'Work the way best for you',
    subtitle:
      'Work the way you want to and take advantage of both visualisation approaches.',
  },
];
