/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import Visualisation from 'views/Visualisation';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import SEO from '../src/components/SEO';

export const Head = () => {
  <SEO description="Visualise and interpret your digital outcrop data in VRGS." />;
};

const VisualisationPage = () => {
  return <WithLayout component={Visualisation} layout={Main} />;
};

export default VisualisationPage;
