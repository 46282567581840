import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Grid from '@mui/material/Grid/Grid';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';

const useStyles = makeStyles((theme) => ({
  videoIframe: {
    boxShadow: `0 5px 15px 0 ${theme.palette.cardShadow}`,
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
    },
  },
}));

const VideoSection = (props) => {
  const {
    data,
    className,
    videoSrc,
    videoTitle,
    videoSubtitle,
    buttonName,
    buttonLink,
    ...rest
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2}>
      <Grid item xs={12} md={6} data-aos={'fade-up'}>
        <div
          data-aos="flip-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <iframe
            className={classes.videoIframe}
            title="video"
            width="100%"
            height="315"
            src={videoSrc}
            frameBorder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
      </Grid>
      <Grid item xs={12} md={6} data-aos={'fade-up'}>
        <SectionHeader
          title={videoTitle}
          titleVariant="h1"
          subtitle={videoSubtitle}
          align={isMd ? 'left' : 'center'}
        />
      </Grid>
    </Grid>
  );
};

VideoSection.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default VideoSection;
